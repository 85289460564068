<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>{{ $t('satisfactionPerDay') }}</b-card-title>
    </b-card-header>

    <!-- chart -->
    <b-card-body>
      <chartjs-component-bar-chart
        :height="400"
        :data="this.series"
        :options="latestBarChart.options"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle, BCardSubTitle,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import ChartjsComponentBarChart from '@/views/habit/charts-components/ChartjsComponentBarChart.vue'
import { $themeColors } from '@themeConfig'
import i18n from '@/libs/i18n'
import { EventBus } from "@/views/habit/EventBus.js";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    flatPickr,
    ChartjsComponentBarChart,
  },
  props: {
    happinessData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      latestBarChart: {
        options: {
          onClick: function (c, i) {
            let e = i[0];
            if (!e) return
            var x_value = this.data.labels[e._index];
            // var y_value = this.data.datasets[0].data[e._index];
            EventBus.$emit("filterByDate", x_value);
          },
          hover: {
            onHover: function(e) {
              var point = this.getElementAtEvent(e)
              if (point.length) e.target.style.cursor = 'pointer';
              else e.target.style.cursor = 'default';
            },
            animationDuration: 0
          },
          elements: {
            rectangle: {
              borderWidth: 2,
              borderSkipped: 'bottom',
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          legend: {
            display: false,
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: 'rgba(0, 0, 0, 0.25)',
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
            callbacks: {
              label: function(tooltipItem, data) {
                let label = data.datasets[tooltipItem.datasetIndex].label || ''
                if (label) label += ': '
                let valueToDisplay = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
                let totalSum = data.datasets[tooltipItem.datasetIndex].totalDone[tooltipItem.index]
                let finalValue = Math.round((valueToDisplay*totalSum)/100)
                if (data.datasets[tooltipItem.datasetIndex].type === "line") {
                  return label + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] + '%'
                }
                return label + finalValue
              }
            }
          },
          scales: {
            xAxes: [
              {
                stacked: true,
                display: true,
                gridLines: {
                  display: true,
                  color: 'rgba(200, 200, 200, 0.2)',
                  zeroLineColor: 'rgba(200, 200, 200, 0.2)',
                },
                scaleLabel: {
                  display: false,
                },
                ticks: {
                  fontColor: '#6e6b7b',
                },
              },
            ],
            yAxes: [
              {
                id: "left-y-axes",
                type: "linear",
                position: "left",
                stacked: true,
                display: true,
                gridLines: {
                  color: 'rgba(200, 200, 200, 0.2)',
                  zeroLineColor: 'rgba(200, 200, 200, 0.2)',
                },
                ticks: {
                  min: 0,
                  max: 100,
                  precision: 0,
                  fontColor: '#6e6b7b',
                  callback: function(value, index, values) {
                    return value + '%';
                  }
                },
                scaleLabel: {
                  display: true,
                  fontSize: 14
                },
              },
              {
                id: "right-y-axes",
                type: "linear",
                position: "right",
                display: false,
                gridLines: {
                  color: 'rgba(200, 200, 200, 0.2)',
                  zeroLineColor: 'rgba(200, 200, 200, 0.2)',
                },
                ticks: {
                  stepSize: 25,
                  min: 0,
                  max: 100,
                  fontColor: '#6e6b7b',
                  callback: function(value, index, values) {
                    return value + '%';
                  }
                },
                scaleLabel: {
                  display: true,
                  labelString: i18n.t('opinions.satisfaction_'),
                  fontSize: 14
                },
              },
            ],
          },
          animation: {
            duration: 1,
            onComplete: function () {
              const chartInstance = this.chart;
              const ctx = chartInstance.ctx;
              ctx.font = Chart.helpers.fontString(14, 'bold', Chart.defaults.global.defaultFontFamily);
              ctx.textAlign = 'center';
              ctx.textBaseline = 'bottom';
              ctx.fillStyle = '#515151';
 
              this.data.datasets.forEach(function (dataset, i) {
                const meta = chartInstance.controller.getDatasetMeta(i);
                  if (meta.type === "bar") {
                    meta.data.forEach(function (bar, index) {
                      const data = dataset.data[index];
                      if (data) {
                        const totalSum = dataset.totalDone[index]
                        const finalValue = Math.round((data * totalSum) / 100)
                        ctx.fillText(finalValue, bar._model.x, bar._model.y + data * 1.75);
                      }
                    });
                  }
              });
            }
          }
        },
      },
      // rangePicker: ['2019-05-01', '2019-05-10'],
    }
  },
  computed: {
    series() {
      const data = this.happinessData.data
      const dataObject = {
        happy: [],
        neutral: [],
        sad: [],
      }
      const totalDone = []
      const compliance = []
      for (const dataInside of data){
        const [ happyComplaince, neutralComplaince, sadComplaince ] = dataInside
        const totalSum = happyComplaince + neutralComplaince + sadComplaince
        totalDone.push(totalSum)
        dataObject.happy.push(Math.round((happyComplaince/totalSum)*100))
        dataObject.neutral.push(Math.round((neutralComplaince/totalSum)*100))
        dataObject.sad.push(Math.round((sadComplaince/totalSum)*100))
        const totalSumPoints = happyComplaince * 2 + neutralComplaince
        const totalSumReactions = totalSumPoints + sadComplaince
        if (totalSumReactions === 0) {
          compliance.push(null)
        } else {
          compliance.push(Math.round((totalSumPoints / totalSumReactions)*100))
        }
      }
      return {
        labels: this.happinessData.labels,
        datasets: [
          {
            data: dataObject.happy,
            yAxisID: 'left-y-axes',
            backgroundColor: $themeColors.success,
            borderColor: 'transparent',
            barThickness: 15,
            order: 2,
            label: i18n.t('happiness.happiness_happy'),
            totalDone: totalDone
          },
          {
            data: dataObject.neutral,
            yAxisID: 'left-y-axes',
            backgroundColor: $themeColors.warning,
            borderColor: 'transparent',
            barThickness: 15,
            order: 2,
            label: i18n.t('happiness.happiness_neutral'),
            totalDone: totalDone
          },
          {
            data: dataObject.sad,
            yAxisID: 'left-y-axes',
            backgroundColor: '#E5737B',
            borderColor: 'transparent',
            barThickness: 15,
            order: 2,
            label: i18n.t('happiness.happiness_sad'),
            totalDone: totalDone
          },
          // {
          //   type: 'line',
          //   label: i18n.t('opinions.satisfaction_'),
          //   data: compliance,
          //   yAxisID: 'right-y-axes',
          //   backgroundColor:'#706F6F',
          //   borderColor: '#706F6F',
          //   lineTension: 0,
          //   fill: false,
          //   pointRadius: 5,
          //   order: 1,
          //   totalDone: totalDone
          // }
        ],
      }
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
